
function initMap() {
    var maps = [
        {
            id: 'minade',
            position: {
                lat: 33.25454070000001,
                lng: 130.3036524
            },
        },
    ];

    var styles = [
        {
            stylers: [
                { hue: '' },
                { saturation: '-100' },
                { lightness: '0' },
                { visibility: 'undefined' },
                { invert_lightness: false }
            ]
        },
        {
            elementType: 'labels.icon',
            stylers: [
                { hue: '' },
                { saturation: '-55' },
                { lightness: '0' },
                { visibility: 'on' }
            ]
        },
    ];

    function initialize() {
        maps.forEach(function (value) {
            var map = new google.maps.Map(document.getElementById(value.id), {
                center: value.position,
                zoom: 16,
                styles: styles,
                mapTypeControl: false
            });

            new google.maps.Marker({
                position: value.position,
                map: map,
            })
        });
    }
    google.maps.event.addDomListener(window, 'load', initialize);

};

